<template>
    <div class="col-6 col-xs-12 col-md-8">
        <v-sheet class="dense-inputs">
            <v-row no-gutters>
                <v-col cols="12" lg="12" md="12" xs="12" class="d-flex flex-row float-right">
                    <v-text-field
                            :label="$t('message.filterResults')"
                            :value="searchTerm"
                            autocomplete="off"
                            class="mt-2 mb-0 force-text-left"
                            prepend-inner-icon="mdi-filter-outline"
                            @change="searchTerm = $event"
                    />
                    <v-btn class="ml-3" :loading="loading.filterResults" @click="filterResults">{{ $t('message.filter') }}</v-btn>
                    <v-btn class="ml-3" @click="searchTerm = null"><v-icon>refresh</v-icon></v-btn>
                    <ExportTable
                            :has-add-function="true"
                            :cols-to-delete="colsToDelete"
                            class="mt-0 mr-0"
                            file-name="deliveryAddresses"
                            table-id="deliveryAddressesTable"
                            style="padding-top: 2px !important;"
                            @add-action="editDialog = true"
                    />
                </v-col>
            </v-row>
        </v-sheet>
        <div style="position: relative">
            <v-overlay
                :value="loading.deliveryAddresses"
                absolute
                opacity="0.15"
            >
                <v-row>
                    <v-col class="text-center">
                        <v-progress-circular
                            color="primary"
                            indeterminate
                            size="40"
                            width="6"
                        ></v-progress-circular>
                    </v-col>
                </v-row>
            </v-overlay>
            <v-data-table
                :footer-props="{
                    itemsPerPageOptions: [20,50,-1],
                    showCurrentPage: true,
                    showFirstLastPage: true
                }"
                :headers="headers"
                :height="tableHeight"
                :items="allDeliveryAddresses"
                :items-per-page="-1"
                :search="searchTerm"
                :options.sync="tableOptions"
                calculate-widths
                class="mt-0 appic-table-light specification-table"
                dense
                fixed-header
                id="deliveryAddressesTable"
                item-key="SdaAddress.id"
                sort-by="SdaAddress.description"
            >
                <template v-slot:item.index="{ item }">
                    <div class="text-center">{{ allSalesColleagues.indexOf(item) + 1}}</div>
                </template>
                <template v-slot:item.SdaAddress.description="{ item }">
                    <span class="font-weight-bold">{{ item.SdaAddress.description }}</span>
                </template>
                <template v-slot:item.SdaAddress.id="{ item }">
                    <div class="text-center">
                        <v-menu>
                            <template v-slot:activator="{ on: menu }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on: tooltip }">
                                        <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                            <v-icon>more_vert</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('message.moreActions') }}</span>
                                </v-tooltip>
                            </template>
                            <v-list dense>
                                <v-list-item class="font-sm" @click="openEditDeliveryAddressDialog(item.SdaAddress.id, item.SdaAddress.description)">
                                    <v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateDeliveryAddress') }}
                                </v-list-item>
                                <v-list-item class="font-sm" @click="deleteDeliveryAddress(item.SdaAddress.id, item.SdaAddress.description)"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.deleteDeliveryAddress') }}</v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </template>
            </v-data-table>
        </div>
        <AddDeliveryAddress
            :delivery-address-id="selectedDeliveryAddressId"
            :delivery-address="selectedDeliveryAddress"
            :dialog.sync="editDialog"
            :update-mode="updateMode"
            @dialog-closed="editDialogClosed"
            @update-done="deliveryAddressUpdated"
            @create-done="deliveryAddressCreated"
        />
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import {mapActions} from 'vuex'

const AddDeliveryAddress = () => import("Components/Appic/AddDeliveryAddress");
const ExportTable = () => import("Components/Appic/ExportTable");

export default {
    name: "ShippingDocumentAddressesListing",
    components: { AddDeliveryAddress, ExportTable },
    props: ['addDialog','reload'],
    data() {
        return {
            colsToDelete: {
                start_col: 0,
                ncols: 1
            },
            dialogs: {
                error: false,
                error_message: ""
            },
            editDialog: null,
            filterOptions: {
                office: 1
            },
            hideDefaultFooter: true,
            loading: {
                fetch: false,
                filterResults: false,
                deliveryAddresses: false
            },
            searchField: null,
            searchTerm: null,
            selectedDeliveryAddressId: null,
            selectedDeliveryAddress: null,
            tableHeight: '500',
            tableOptions: {
                page: 1
            },
            totalDeliveryAddresses: 0,
            updateMode: false
        }
    },
    computed: {
        ...mapFields('sdaaddress',{
            allDeliveryAddresses: 'allDeliveryAddresses'
        }),
        headers () {
            return [
                {
                    id: 1,
                    text: this.$t('message.actions'),
                    value: 'SdaAddress.id',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    sortable: false
                },
                {
                    id: 2,
                    text: this.$t('message.title'),
                    value: 'SdaAddress.description',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 3,
                    text: this.$t('message.agent'),
                    value: 'SdaAddress.agent',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 4,
                    text: this.$t('message.destinationCountry'),
                    value: 'SdaAddress.Country.name',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 5,
                    text: this.$t('message.destinationPort'),
                    value: 'SdaAddress.ShippingPort.title',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 6,
                    text: this.$t('message.salesType'),
                    value: 'SdaAddress.SalesType.title',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 7,
                    text: this.$t('message.buyer'),
                    value: 'SdaAddress.Customer.otsname',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                }
            ]
        }
    },
    methods: {
        ...mapActions('sdaaddress',{
            deleteDeliveryAddressById: 'deleteDeliveryAddressById',
            getAllDeliveryAddresses: 'getAllDeliveryAddresses',
            resetCurrentState: 'resetCurrentState',
            resetState: 'resetState'
        }),
        async deleteDeliveryAddress (val, title) {
            if(await this.$root.$confirm(this.$t('message.deleteDeliveryAddress') + ' ' +  title, this.$t('message.confirmations.continueDeliveryAddressCancelAction'), {color: 'orange'})){
                this.deleteDeliveryAddressById(val)
                    .then((response) => {
                        if(response.status == 'success'){
                            this.$toast.success(this.$t('message.successes.deliveryAddressDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.resetState()
                            this.getAllDeliveryAddresses()
                        } else {
                            this.$toast.error(this.$t('message.errors.deliveryAddressNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(error => {
                        this.$toast.error(this.$t('message.errors.deliveryAddressNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            }
        },
        editDialogClosed () {
            this.editDialog = false;
            if(this.updateMode == false){
                this.$emit('add-dialog-closed')
            } else {
                this.updateMode = false
                this.selectedDeliveryAddressId = null
                this.selectedDeliveryAddress = null
            }
        },
        filterResults () {
            this.loading.filterResults = true
            let _this = this
            setTimeout(function(){
                _this.loading.filterResults = false
            },700)
        },
        handleResize() {
            this.tableHeight = window.innerHeight - (285);
        },
        openCreateDeliveryAddressDialog () {
            this.updateMode = false
            this.editDialog = true
        },
        openEditDeliveryAddressDialog (deliveryAddressId, deliveryAddress) {
            this.selectedDeliveryAddressId = deliveryAddressId
            this.selectedDeliveryAddress = deliveryAddress
            this.updateMode = true
            this.editDialog = true
        },
        deliveryAddressCreated () {
            this.editDialog = false;
            this.resetState()
            this.resetCurrentState()
            this.$emit('reload')
        },
        deliveryAddressUpdated () {
            this.editDialog = false;
            this.resetState()
            this.$emit('reload')
        },
    },
    watch: {
        addDialog(value) {
            if(value) {
                this.openCreateDeliveryAddressDialog()
            }
        }
    },
    created() {
        this.resetState()
        if(this.allDeliveryAddresses.length == 0) {
            this.loading.deliveryAddresses = true
            this.getAllDeliveryAddresses()
                .then(() => {
                    this.loading.deliveryAddresses = false
                })
                .catch(() => {
                    this.loading.deliveryAddresses = false
                })
        }
        window.addEventListener('resize', this.handleResize)
    },
    mounted() {
        this.handleResize()
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style>
.font-xs {
    font-size: 0.65rem;
}
.mt-5px {
    margin-top: 5px !important;
}
.mt-6px {
    margin-top: 6px !important;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
</style>